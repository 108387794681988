import React from 'react';

export const SignUp = () => {
  return (
    <div className="signup-container">
      <h1 className="title">Sign Up to participate in Nutracker 2024</h1> 
      <div className="iframe-container">
      <iframe 
          title="Sign Up Form" 
          src="https://docs.google.com/forms/d/e/1FAIpQLSceu-CjeOeo3unZeV-yv6CSmBl6R5FsxC_cTEwZ3R8f4C9eoQ/viewform?embedded=true"
          width="100%" 
          height="100%" 
          frameborder="0" 
          marginheight="0" 
          marginwidth="0">
            Loading…
        </iframe>
      </div>
    </div>
  );
};

