import React from 'react';
import { SignUp } from './Signup';

export const Home = () => {
  return (
    <div className="page">
      <SignUp/>
    </div>
    
  );
};